import React from "react";
import Header from "../components/home/Header";
import Footer from "../components/home/Footer";
import Login from "../components/login/Login4";

function LoginPage() {
  return (
    <>
      <Header />
      <Login />
      <Footer />
    </>
  );
}

export default LoginPage;